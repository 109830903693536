<template>
<!--  todo complete this after model-pages   -->
  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-dark bg-white" id="sidenavAccordion">
      <div class="sb-sidenav-menu e-bg-red rounded">
        <ul class="nav navbar-nav p-2">
          <template v-if="user.vessel_id == null">
            <div class="text-white pt-4">
              <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
              DASHBOARD
            </div>
            <router-link class="nav-item e-text-white ml-4" :class="{'active': isUnderNumberOfManagement }" :to="{ name: 'NumberOfManagement', params : {
              report_type : 'flag'
            }}">
              <font-awesome-icon icon="th-large" class="force-white-all" style="width: 25px" />
              <small>VESSEL INFO</small>
            </router-link>
            <router-link class="nav-item e-text-white ml-4" :class="{'active': isUnderAiDashBoard}" :to="{ name: 'AiDashboard', params : {
              chart_module : 'number_of_report'
            }}">
              <font-awesome-icon icon="th-large" class="force-white-all" style="width: 25px" />
              <small>ACCIDENT / INCIDENT</small>
            </router-link>
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'PscDashboard'}">
              <font-awesome-icon icon="th-large" class="force-white-all" style="width: 25px" />
              <small>PSC INSPECTION</small>
            </router-link>
            <hr class="my-0" />
          </template>
          <div class="text-white pt-4">
            <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
            REPORT LIST</div>
          <router-link class="nav-item e-text-white ml-4" :to="{ name: 'Module'}"
                       v-if="isAllowed('menu.reports.accident-incident')">
            <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
            <small>
              ACCIDENT / INCIDENT
            </small>
          </router-link>
          <router-link class="nav-item e-text-white ml-4" :class="{'active':$route.name ==='PscIndex'}" :to="{ name: 'Psc'}"
                       v-if="isAllowed('menu.reports.psc-inspection')">
            <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
            <small>
              PSC INSPECTION
            </small>
          </router-link>
          <a class="nav-item e-text-white e-bg-red-light-3 ml-4 no-hover"
             v-if="isAllowed('menu.reports.rightship-inspection')">
            <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
            <small>
              RIGHTSHIP INSPECTION
            </small>
          </a>
          <a class="nav-item e-text-white e-bg-red-light-3 ml-4 no-hover"
             v-if="isAllowed('menu.reports.internal-audit')">
            <font-awesome-icon icon="file-signature" class="force-white-all" style="width: 25px" />
            <small>
              INTERNAL AUDIT
            </small>
          </a>
          <a class="nav-item e-text-white e-bg-red-light-3 ml-4 no-hover"
             v-if="isAllowed('menu.reports.master-review')">
            <font-awesome-icon icon="chart-line" class="force-white-all" style="width: 25px;" />
            <small>
              MASTER'S REVIEW AND IMPROVEMENT
            </small>
          </a>
          <hr class="my-0" />
          <template v-if="isElite() || isAllowed('menu.company-instruction')">
            <template v-if="user.vessel_id == null">
              <div class="text-white pt-4">
                <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
                COMPANY INSTRUCTION
              </div>
              <router-link class="nav-item e-text-white ml-4"
                           :to="{ name: 'StaffCiAir', query:{type:'air',t:new Date().getTime()}}"

                           v-if="isAllowed('menu.company-instruction.accident-incident')">
                <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                <small>
                  ACCIDENT / INCIDENT
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4"
                           :to="{ name: 'StaffCiPsc',query:{type:'psc',t:new Date().getTime()}}"
                           v-if="isAllowed('menu.company-instruction.psc-inspection')">
                <font-awesome-icon icon="file-contract" class="force-white-all" style="width: 25px" />
                <small>
                  PSC INSPECTION
                </small>
              </router-link>
            </template>
            <template v-else>
              <div class="text-white pt-4">
                <font-awesome-icon icon="clipboard" class="force-white-all" style="width: 25px" />
                COMPANY INSTRUCTION</div>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'MasterCiAir',query:{type:'air',t:new Date().getTime()}}">
                <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                <small>
                  ACCIDENT / INCIDENT
                </small>
              </router-link>
              <router-link class="nav-item e-text-white ml-4" :to="{ name: 'MasterCiPsc',query:{type:'psc',t:new Date().getTime()}}">
                <font-awesome-icon icon="notes-medical" class="force-white-all" style="width: 25px" />
                <small>
                  PSC INSPECTION
                </small>
              </router-link>
            </template>
            <hr class="my-0" />
          </template>
          <template v-if="isAllowed('menu.vessels')">
            <router-link class="nav-item e-text-white" :to="{ name: 'Vessel'}"
                         v-if="user.vessel_id == null">
              <font-awesome-icon icon="ship" class="force-white-all" style="width: 25px" />
              VESSELS
            </router-link>
            <router-link class="nav-item e-text-white" :to="`/vessels/${user.vessel_id}/particular`"
                         v-else>
              <font-awesome-icon icon="ship" class="force-white-all" style="width: 25px" />
              VESSEL
            </router-link>
            <hr class="my-0" />
          </template>
          <template v-if="isElite()">
            <router-link class="nav-item e-text-white" :to="{ name: 'ShipsPI'}"
                         v-if="isAllowed('menu.ships-pi')">
              <font-awesome-icon icon="info-circle" class="force-white-all" style="width: 25px" />
              SHIP'S PI
            </router-link>
            <hr class="my-0" />
            <div class="text-white pt-4">
              <font-awesome-icon icon="cogs" class="force-white-all" style="width: 25px" />
              SETTINGS</div>
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsUsers'}"
                         v-if="isAllowed('menu.settings.user')">
              <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
              <small>
                USERS
              </small>
            </router-link>
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsCurrencies'}"
                         v-if="isAllowed('menu.settings.currency')">
              <font-awesome-icon icon="money-bill" class="force-white-all" style="width: 25px" />
              <small>
                CURRENCIES
              </small>
            </router-link>
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsEvents'}"
                         v-if="isAllowed('menu.settings.event')">
              <font-awesome-icon icon="calendar" class="force-white-all" style="width: 25px" />
              <small>
                EVENTS
              </small>
            </router-link>
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SettingsCauses'}"
                         v-if="isAllowed('menu.settings.cause')">
              <font-awesome-icon icon="car-crash" class="force-white-all" style="width: 25px" />
              <small>
                CAUSES
              </small>
            </router-link>
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'SisterShip'}"
                         v-if="isAllowed('menu.settings.sister-ship')">
              <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
              <small>
                SISTER SHIP
              </small>
            </router-link>
<!--            v-if="isAllowed('menu.settings.sister-ship')"-->
            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'RelatedParty'}">
              <font-awesome-icon icon="users" class="force-white-all" style="width: 25px" />
              <small>
                RELATED PARTIES
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineMaker'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE MAKER
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineType'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE TYPE
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineDgMaker'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE D/G MAKER
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineDgType'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE D/G TYPE
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineTcMaker'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE T/C MAKER
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineTcType'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE T/C TYPE
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineAuxMaker'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE AUX MAKER
              </small>
            </router-link>

            <router-link class="nav-item e-text-white ml-4" :to="{ name: 'EngineAuxType'}" v-if="isElite">
              <font-awesome-icon icon="cog" class="force-white-all" style="width: 25px" />
              <small>
                ENGINE AUX TYPE
              </small>
            </router-link>
          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: 'MainSidebar',
  methods: {
    ...mapActions(['pullUserDetails']),
    async vesselOverview(vesselId) {
      await this.$router.push({
        name: "VesselParticular",
        params: {id: vesselId},
      });

    },
    isActiveRouteCompanyInstruction(routeName,type){
      let currentRouteName = this.$router.currentRoute.name
      if(currentRouteName==routeName){
        if(!!this.$route.query.type) return this.$route.query.type==type
      }
      return false;
    },
  },
  mixins: [permissionMixin],

  created () {
    this.pullUserDetails()
  //   todo call the permission getters to backend here
  },
  computed: {
    ...mapGetters(['user']),
    isUnderAiDashBoard() {
      return this.$route.name === 'AiDashboard';
    },
    isUnderNumberOfManagement() {
      return this.$route.name === 'NumberOfManagement'
    }
  }
}
</script>

<style scoped lang="scss">
.nav > a {
  margin: .35rem 0;
  padding: .5rem;
  border-radius: 5px;
  text-decoration: none;
  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.active {
  background-color: white;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}
.router-link-active {
  background-color: white;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}
.no-hover {
  pointer-events: none;
}
</style>
